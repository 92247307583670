import React from "react";
import Breadcrumb1 from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Riskmanagement from "../components/Riskmanagement";
import Header from "../components/Header";

const LIFERISK = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb1 name="Gestion des risques" />
        <Riskmanagement />
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default LIFERISK;
