import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import blogimg1 from "../img/blog/1.jpg";
import blogimg2 from "../img/blog/2.jpg";
import blogimg3 from "../img/blog/3.jpg";
import { Link } from "react-router-dom";
const GlobalClients = () => {
  const [isOpen, setOpen] = useState(false);

  const options = {
    loop: true,
    autoplay: true,
    animateIn: "zoomInDown",
    smartSpeed: 5000,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="snvzakfcTmY"
        onClose={() => setOpen(false)}

      />
      <div className="single-blog-content" style={{ textAlign: 'center', color: 'red', fontSize: '24px' }}>
 
  <h4>
    <Link to="#" className="post-title">
    Clients et solutions mondiaux chez X-RE
    </Link>
  </h4>
</div>


      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
     

        <div className="container">
          <div className="row">
            
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">
                <div className="blog-img-slider">
                  <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    <img src={blogimg1} alt="blog-img" />
                    <img src={blogimg2} alt="blog-img" />
                    <img src={blogimg3} alt="blog-img" />
                  </OwlCarousel>
                </div>
                <div className="single-blog-content">
                  {/*<div className="blog-date">14 nov 2019</div>*/}
                  <h4>
                   {/*} <Link to="" className="post-title">
                      contrary to popular belief, lorem ipsum is
  </Link>*/}
                  </h4>
                  <p>
                     <p>
                     Chez X-RE, nous nous engageons à fournir des solutions complètes et sur mesure pour répondre aux besoins uniques de nos clients mondiaux. Notre engagement envers l’excellence, l’innovation et la satisfaction client anime notre division Global Clients & Solutions.

                  </p>
                  <p><b>Solutions sur mesure : </b>Nous comprenons que chaque client est unique, avec des besoins et des défis spécifiques. C'est pourquoi notre équipe Global Clients & Solutions travaille en étroite collaboration avec vous pour créer des solutions personnalisées qui s'adaptent comme un gant à votre entreprise.</p>
                  <p><b>Portée mondiale : </b>Grâce à un réseau mondial et une compréhension approfondie des marchés internationaux, nous pouvons vous aider à naviguer dans les complexités de l’assurance et de la réassurance mondiales.</p>

                  <p><b>Gestion des risques : </b>nos experts sont compétents en matière d’évaluation et de gestion des risques. Nous vous fournissons les outils et les connaissances nécessaires pour prendre des décisions éclairées concernant votre couverture.</p>

                  <p> <b>Innovation : </b>Nous restons à l’avant-garde du secteur de l’assurance en adoptant l’innovation et la technologie. Notre équipe explore continuellement de nouvelles façons d’améliorer votre expérience en assurance et en réassurance.</p>

                  </p>
                  {/*<div className="post-info">
                    <Link to="">by admin</Link>
                    <Link to="">0 comments</Link>
  </div>*/}
                </div>
              </div>
            </div>
          

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">
                <div className="blog-img-slider">
                  <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    <img src={blogimg1} alt="blog-img" />
                    <img src={blogimg2} alt="blog-img" />
                    <img src={blogimg3} alt="blog-img" />
                  </OwlCarousel>
                </div>
                <div className="single-blog-content">
                  {/*<div className="blog-date">14 nov 2019</div>*/}
                  <h4>
                    {/*<Link to="" className="post-title">
                      contrary to popular belief, lorem ipsum is
                  </Link>*/}
                  </h4>
                  
                     <p>
                                  
                  <p><b>Support dédié : </b>lorsque vous vous associez à X-RE, vous n'êtes pas seulement un client ; vous faites partie de notre famille élargie. Notre équipe Global Clients & Solutions se consacre à assurer votre succès, à vous offrir un soutien continu et à répondre rapidement à vos besoins.</p>
                  <p><b>Service exceptionnel : </b>Nous sommes fiers de fournir un service exceptionnel. Notre engagement envers l’excellence s’étend à tous les aspects de notre relation avec vous.</p>

                  <p><b>Couverture complète : </b>Que vous recherchiez une couverture dans des domaines traditionnels ou sur des marchés émergents, notre équipe Clients et solutions mondiaux a ce qu'il vous faut.</p>
                  <p><b>Durabilité : </b>:Nous comprenons l’importance des pratiques commerciales durables. Nos solutions tiennent compte de l’impact à long terme et vous aident à faire des choix respectueux de l’environnement.</p>
                  <p><b>Confiance : </b>X-RE est un nom auquel vous pouvez faire confiance. Notre réputation d'intégrité et de fiabilité nous précède dans le secteur de l'assurance et de la réassurance.</p>
                  <p>Découvrez comment les clients et solutions mondiaux de X-RE peuvent faire la différence pour votre entreprise. Contactez-nous dès aujourd'hui pour découvrir comment nos solutions sur mesure, notre expertise mondiale et notre engagement envers l'excellence peuvent vous être bénéfiques. Votre succès est notre succès.</p>
       
                  </p>
                  {/*<div className="post-info">
                    <Link to="">by admin</Link>
                    <Link to="">0 comments</Link>
                  </div>*/}
                </div>
              </div>
            </div>

            <div className="col-xl-12">
              <div className="merox-pagination mt-20">
                <nav className="pagination">
                  <div className="nav-links">
                   {/* <span aria-current="page" className="page-numbers current">
                      1
                    </span>
                    <Link className="page-numbers" to="">
                      2
                    </Link>
                    <Link className="next page-numbers" to="">
                      <i className="fa fa-long-arrow-right"></i>
                    </Link>*/ }
                  </div>
                </nav>
              </div>
        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobalClients;
