import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import blogimg1 from "../img/blog/1.jpg";
import blogimg2 from "../img/blog/2.jpg";
import blogimg3 from "../img/blog/3.jpg";
import { Link } from "react-router-dom";
import servbg from "../img/services/services-bg.jpg";
const Riskmanagement= () => {
  const [isOpen, setOpen] = useState(false);

  const options = {
    loop: true,
    autoplay: true,
    animateIn: "zoomInDown",
    smartSpeed: 5000,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="snvzakfcTmY"
        onClose={() => setOpen(false)}
      

      />
   
      <div className="single-blog-content" style={{ textAlign: 'center', color: 'red', fontSize: '24px' }}>
      {/*<img src={servbg} alt="img" />*/}
  <h4>
    <Link to="#" className="post-title">
    Gestion des Risques d'Entreprise avec <b>X-RE </b>
    </Link>
  </h4>
</div>


      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
     

        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">
                
                 {/* <img src={blogimg1} alt="blog-img" />*/}
                 
                 {/*<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">*/}
                <div className="blog-img-slider">
                  <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    <img src={blogimg1} alt="blog-img" />
                    <img src={blogimg2} alt="blog-img" />
                    <img src={blogimg3} alt="blog-img" />
                  </OwlCarousel>
                </div>
                <div className="single-blog-content">
                  {/*<div className="blog-date">14 nov 2019</div>*/}
                  <h4>
                    
                  </h4>
                  <p>
                  <p><b>Pourquoi X-RE pour la gestion des risques ? </b> 
</p>
<p><b>
Expertise Approfondie  : </b> Nous avons une connaissance approfondie des risques dans divers secteurs, ce qui nous permet de vous fournir des solutions adaptées.  </p>

<p><b>Approche Personnalisée  : </b>  Chaque entreprise est unique. Nous personnalisons nos services pour répondre à vos besoins spécifiques en matière de gestion des risques.  </p>

<p><b> Réseau Étendu : </b> X-RE entretient des relations solides avec un vaste réseau de compagnies de réassurance à travers le monde, nous permettant de vous offrir des solutions compétitives. 
</p>
<p><b>Engagement envers l'Excellence : </b> Nous nous engageons à fournir un service client exceptionnel et à être votre partenaire de confiance dans la gestion des risques. 
</p>
<p>Protégez votre entreprise, optimisez vos performances et assurez votre pérennité grâce à une gestion proactive des risques avec X-RE. Contactez-nous dès aujourd'hui pour discuter de vos besoins en gestion des risques. Votre tranquillité d'esprit est notre priorité.</p>
                  </p>
                
         
                  </div>

                  
                  
                
              </div>
            </div>


            {/*/<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">
                 <div className="blog-img">
                 <img src={blogimg2} alt="blog-img" />
                </div>
                <div className="single-blog-content">
                  <div className="blog-date">25 jan 2008</div>
                  <h4>
                    <Link to="" className="post-title">
                      there are many variations of passages of but
  </Link>
                  </h4>
               


                  <div className="post-info">
                    <Link to="">by admin</Link>
                    <Link to="">1 comments</Link>
                  </div>
                </div>
              </div>
            </div>*/}
           

             <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">
                <div className="blog-img-slider">
                  <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    <img src={blogimg1} alt="blog-img" />
                    <img src={blogimg2} alt="blog-img" />
                    <img src={blogimg3} alt="blog-img" />
                  </OwlCarousel>
                </div>
                <div className="single-blog-content">
                 {/* <div className="blog-date">14 nov 2019</div>*/}
                   {/* <h4>
                    <Link to="" className="post-title">
                      contrary to popular belief, lorem ipsum is
                    </Link>
                  </h4>*/}
                  <p>
                  <p>
                  Chez X-RE, nous comprenons que la gestion des risques d'entreprise est essentielle pour assurer la stabilité et la croissance de votre organisation. Notre engagement est de vous aider à identifier, évaluer et gérer ces risques de manière proactive. Nous proposons des solutions de courtage en réassurance qui vous permettent de minimiser l'impact financier des risques potentiels sur votre entreprise.
                  </p>
                  <p><b>Nos Services de Gestion des Risques : </b>   </p>
                  <p><b>Analyse des Risques : </b>Notre équipe d'experts analyse en profondeur les risques auxquels votre entreprise est exposée, en identifiant les vulnérabilités et les opportunités d'amélioration. </p>

                  <p><b>Conception de Programmes de Réassurance : </b>Nous élaborons des programmes de réassurance sur mesure pour couvrir vos risques spécifiques, que ce soit en matière de responsabilité civile, de dommages matériels, de risques liés aux activités ou d'autres domaines. 
                  </p>

                  <p> <b>
                  Évaluation des Performances : </b>Nous surveillons en permanence la performance de vos programmes de réassurance, en ajustant les stratégies au besoin pour garantir une protection optimale. 
                  </p>

                  <p><b>
                  Gestion des Sinistres : </b>En cas de sinistre, notre équipe expérimentée vous soutient dans la gestion des réclamations, en veillant à ce que vous obteniez une résolution rapide et équitable.</p>

                  </p>
                 
                </div>
              </div>
            </div>

           {/* <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">
                <div className="blog-img-slider">
                  <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    <img src={blogimg1} alt="blog-img" />
                    <img src={blogimg2} alt="blog-img" />
                    <img src={blogimg3} alt="blog-img" />
                  </OwlCarousel>
                </div>
                <div className="single-blog-content">
                  {/*<div className="blog-date">14 nov 2019</div>
                  <h4>
                    
                  </h4>
                  <p>
                  <p><b>Why choose X-RE for Risk Management ? </b> 
</p>
<p><b>
In-depth expertise: </b> We have in-depth knowledge of risks in various sectors, enabling us to provide you with tailored solutions.  </p>

<p><b>Customised approach: </b>  Every business is unique. We tailor our services to meet your specific risk management needs.  </p>

<p><b> Extensive Network: </b> X-RE has strong relationships with an extensive network of reinsurance companies around the world, enabling us to offer you competitive solutions. 
</p>
<p><b>Commitment to Excellence: </b> We are committed to providing exceptional customer service and being your trusted risk management partner. 
</p>
<p>Protect your business, optimise your performance and secure your future through proactive risk management with X-RE. Contact us today to discuss your risk management needs. Your peace of mind is our priority.</p>
                  </p>
                
                </div>
          </div>
                  </div>*/}
            <div className="col-xl-12">
              <div className="merox-pagination mt-20">
                <nav className="pagination">
                  <div className="nav-links">
                   {/* <span aria-current="page" className="page-numbers current">
                      1
                    </span>
                    <Link className="page-numbers" to="">
                      2
                    </Link>
                    <Link className="next page-numbers" to="">
                      <i className="fa fa-long-arrow-right"></i>
                </Link>*/}
                  </div>
                </nav>
              </div>
        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Riskmanagement;
