import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import wdg1 from "../img/wdg/wdg-img-1.jpg";
import wdg2 from "../img/wdg/wdg-img-2.jpg";
import wdg3 from "../img/wdg/wdg-img-3.jpg";
import blogimg1 from "../img/blog/1.jpg";
import blogimg2 from "../img/blog/2.jpg";
import blogimg3 from "../img/blog/3.jpg";
const BlogList = () => {
  const [isOpen, setOpen] = useState(false);
  const options = {
    loop: true,
    autoplay: true,
    animateIn: "zoomInDown",
    smartSpeed: 5000,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  
  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="snvzakfcTmY"
        onClose={() => setOpen(false)}
      />
      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-8 pr-30">
              <div className="col-xl-12">
                <div className="single-blog-wraper mb-30  wow fadeInUp">
                  ,{/*<div className="blog-img">
                    <img src={blogimg1} alt="blog-img" />
                    </div>*/}
                  <div className="single-blog-content">
                    {/*<div className="blog-date">14 nov 2019</div>*/}
                    <h4>
                      <div className="post-title">
                      Risques d'Immobilier
                      </div>
                    </h4>
                    <p>
                    Gestion des risque d'Immobiliers chez X-RE

                    Chez X-RE, nous comprenons les défis uniques auxquels sont confrontées les entreprises et les investisseurs immobiliers. Nos solutions de gestion des risques immobiliers sont conçues pour vous aider à protéger vos investissements précieux tout en maximisant la rentabilité de vos actifs immobiliers.

                    <p><b>• Nos Solutions pour les Risques Immobiliers : </b></p>

                    <b>Assurance Multirisque Immobilière : </b>Protégez vos biens immobiliers contre les dommages matériels, les incendies, les catastrophes naturelles et plus encore avec notre couverture multirisque complète.

                    <p><b>•	Responsabilité Civile Immobilière : </b>Garantissez la sécurité des occupants et des tiers en cas de blessures ou de dommages causés par vos propriétés.</p>
                    
                    <b>• Assurance de Loyers Impayés : </b>Avoid loss of income in the event of unpaid rent with our unpaid rent insurance. 

                    <p><b>•	Risque de Perte de Valeur Immobilière : </b>Protégez la valeur de vos actifs immobiliers contre les fluctuations du marché et les événements imprévus.</p>
                    
                    <p><b>Pourquoi Choisir X-RE pour la Gestion des Risques Immobiliers ?</b></p>
                    
                    <p></p><b>•	Expertise Immobilière : </b>Nos experts en assurance immobilière comprennent les nuances de l'industrie immobilière, vous offrant des solutions adaptées à vos besoins spécifiques.<p/>

                    <p><b>•	Personnalisation : </b>Nous travaillons en étroite collaboration avec vous pour concevoir des programmes d'assurance sur mesure qui correspondent à votre portefeuille immobilier.</p>
                    
                    <p><b>•	Gestion des Réclamations Efficace : </b>En cas de sinistre, notre équipe dédiée de gestion des réclamations est là pour vous aider à obtenir une résolution rapide et équitable.</p>
                    
                    <p><b>•	Analyse des Risques Proactive : </b>Nous travaillons avec vous pour identifier les risques potentiels et mettre en place des mesures préventives.</p>

                    Chez X-RE, nous sommes déterminés à être votre partenaire de confiance dans la gestion des risques immobiliers. Mettez la tranquillité d'esprit au cœur de votre stratégie immobilière en collaborant avec nous.
                    </p>
                    
                    {/*<div className="post-info">
                      <a href="#">by admin</a>
                      <a href="#">0 comments</a>
                    </div>*/}
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="single-blog-wraper mb-30  wow fadeInUp">
                  <div className="blog-img-slider">
                    <OwlCarousel
                      className="owl-carousel owl-theme"
                      {...options}
                    >
                      {/*<img src={blogimg3} alt="blog-img" />
                      <img src={blogimg2} alt="blog-img" />
<img src={blogimg1} alt="blog-img" />*/}
                    </OwlCarousel>
                  </div>
                  <div className="single-blog-content">
                    {/*<div className="blog-date">14 nov 2019</div>*/}
                  
                    <h4>
                      <div className="post-title">
                      Gestion de la Responsabilité Civile avec X-RE
                      </div>
                    </h4>
                      
                    <p>Chez X-RE, nous comprenons l'importance cruciale de la gestion de la responsabilité civile pour protéger votre entreprise contre les risques et les litiges potentiels. Notre engagement envers l'excellence en matière de gestion de la responsabilité civile se reflète dans nos solutions sur mesure et notre expertise inégalée.<p>
                      
                    </p><b>Nos Solutions pour la Responsabilité Civile :</b><p/>

                    <p><b>•	Responsabilité Civile Générale : </b>: Protégez votre entreprise contre les réclamations de tiers liées à des blessures corporelles, des dommages matériels, des diffamations et d'autres risques potentiels.</p>
                    
                    <p><b>•	Responsabilité des Produits : </b>Couvrez-vous contre les réclamations découlant de défauts de produits ou de blessures causées par l'utilisation de vos produits.</p>

                    <p><b>•	Responsabilité des Employeurs : </b>Protégez-vous contre les réclamations de vos employés pour des blessures ou des maladies liées au travail.</p>
                    
                    <p><b>•	Responsabilité des Administrateurs et Dirigeants (D&O) : </b>Protégez vos dirigeants et administrateurs contre les réclamations de responsabilité personnelle découlant de décisions commerciales.</p>
                    
                    <b>Pourquoi Choisir X-RE pour la Gestion de la Responsabilité Civile ?</b>
                    
                    <p><b>•	Analyse Approfondie des Risques : </b>Notre équipe d'experts évalue minutieusement vos risques spécifiques pour élaborer des programmes de responsabilité civile adaptés à vos besoins.</p>
                    
                    <p><b>Personnalisation:</b>Nous travaillons en étroite collaboration avec vous pour concevoir des solutions sur mesure qui correspondent à la taille et à la complexité de votre entreprise.</p>
                    
                    <p><b>•	Service Client de Qualité Supérieure : </b>Notre engagement envers un service client exceptionnel signifie que nous sommes là pour vous aider à chaque étape du processus, de la souscription à la gestion des réclamations.</p>

                    <p><b>•	Gestion des Réclamations Efficace : </b> En cas de réclamation, notre équipe dédiée est prête à vous assister dans le processus de règlement pour une résolution rapide et équitable.</p>

                    Chez X-RE, nous sommes déterminés à vous fournir une tranquillité d'esprit totale en matière de gestion de la responsabilité civile. Faites confiance à notre expertise pour protéger votre entreprise contre les imprévus et les litiges.
                    </p>
          
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="single-blog-wraper mb-30  wow fadeInUp">
                  <div className="blog-video-img text-center position-relative">
                    {/*<img src={blogimg2} alt="blog-img" />
                    <div className="blog-video-icon">
                      <div
                        className="blog-popup-video"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fa fa-play"></i>
                      </div>
</div>*/}
                  </div>
                  <div className="single-blog-content">
                    {/*<div className="blog-date">25 jan 2008</div>*/}
                    
                    <h4>
                      <div className="post-title">
                      Nos Solutions pour les Risques Agricoles
                      </div>
                    </h4>
                     
                    <p>
                    <p><b>•	Assurance Récolte : </b>Protégez vos cultures contre les dommages causés par les intempéries, les maladies, les ravageurs et d'autres risques imprévisibles.</p>
                    </p>
                    
                    <p><b>•	Assurance du Bétail : </b>Garantissez la santé et la sécurité de votre bétail en couvrant les pertes dues à des maladies, des accidents ou d'autres événements imprévus.</p>
                    
                    <p><b>•	Assurance Responsabilité Agricole : </b>Protégez-vous contre les réclamations de tiers liées aux activités agricoles, telles que les dommages causés aux biens ou les blessures.</p>
                    
                    <p><b>•	Assurance Multirisque Agricole : </b>: Obtenez une couverture complète pour votre exploitation agricole, y compris les bâtiments, l'équipement, les récoltes et la responsabilité.</p>
                    
                    <p><b>Pourquoi Choisir X-RE pour la Gestion des Risques Agricoles ? </b></p>
                   
                    <p><b>•	Expertise Agricole : </b> Notre équipe comprend les défis uniques auxquels les agriculteurs sont confrontés et travaille avec vous pour élaborer des programmes d'assurance adaptés.
                    </p>
                     
                    <p><b>
                    •	Personnalisation : </b> Nous concevons des solutions sur mesure en fonction de la taille, du type et des besoins spécifiques de votre exploitation agricole.
                    </p>
                    <p><b>
                    •	Prévention des Risques : </b> En plus de l'assurance, nous offrons des conseils en gestion des risques pour minimiser les pertes potentielles.</p>
                    <p><b>
                    •	Réactivité et Service Client : </b>Nous sommes disponibles pour vous soutenir en cas de sinistre, et notre équipe est réputée pour sa réactivité et son service client exceptionnel.</p>

                    <p>Chez X-RE, nous sommes fiers de contribuer à la stabilité et à la croissance de l'industrie agricole en offrant des solutions d'assurance solides et une expertise de pointe. Faites confiance à X-RE pour la protection de votre exploitation agricole.</p>
                   

                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="single-blog-wraper mb-30  wow fadeInUp">
                  <div className="blog-video-img text-center position-relative">
                    {/*<img src={blogimg2} alt="blog-img" />
                    <div className="blog-video-icon">
                      <div
                        className="blog-popup-video"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fa fa-play"></i>
                      </div>
</div>*/}
                  </div>
                  <div className="single-blog-content">
                    {/*<div className="blog-date">25 jan 2008</div>*/}
                  
                    <h4>
                      <div className="post-title">
                      Assurance Aérienne
                      </div>
                    
                     
                       
                     
                      
                    </h4>
                    <p>
                    
                    <p><b>Assurance Aérienne :</b> : Voler en Toute Confiance avec X-RE</p>
                    L'industrie aéronautique est synonyme de mobilité et de progrès, mais elle comporte également des risques. Chez X-RE, nous comprenons l'importance de voler en toute confiance, que vous soyez une compagnie aérienne, un propriétaire d'aéronef ou une entreprise impliquée dans l'aviation. Nos solutions d'assurance aérienne sont conçues pour vous offrir une protection complète et une tranquillité d'esprit totale.
                    
                    <b>Notre Gamme de Produits pour l'Assurance Aérienne Comprend : </b>
                    </p>

                    <p><b>•	Assurance Aéronefs : </b> Protégez votre flotte d'aéronefs contre les dommages, les pertes et les responsabilités potentielles. Que vous exploitiez des avions commerciaux, des jets privés ou des hélicoptères, nous avons la solution adaptée à vos besoins.
                    </p>
                    
                    <p><b>
                    •	Assurance Responsabilité Civile Aérienne </b>Couvrez-vous contre les éventuelles réclamations de tiers en cas d'accident aérien. Notre assurance responsabilité civile aérienne offre une protection complète pour vous et vos passagers.</p>
                    
                    <p><b>•	Assurance de Maintenance : </b>Gardez votre équipement en parfait état de fonctionnement grâce à notre assurance de maintenance. Elle couvre les réparations et les pièces de rechange nécessaires pour minimiser les temps d'arrêt.
                     </p>
                   
                    <p><b>•	Assurance de Transport de Marchandises : </b> : Si vous êtes impliqué dans le transport aérien de marchandises, notre assurance spécifique garantit la sécurité de vos envois.
                    </p>

                    <p><b>Pourquoi Choisir X-RE pour Votre Assurance Aérienne ?</b></p>
                     
                    <p><b>
                    •	Expertise Sectorielle : </b>Notre équipe d'experts en assurance aérienne possède une connaissance approfondie de l'industrie aéronautique. Nous comprenons les défis spécifiques auxquels vous êtes confrontés.
                    </p>

                    <p><b>•	Sécurité Opérationnelle : </b>X-RE s'engage à garantir la sécurité de vos opérations aériennes. Nous travaillons avec vous pour identifier et minimiser les risques.
                    </p>

                    <p><b>•	Solutions Personnalisées : </b>Nous comprenons que chaque entreprise a des besoins uniques. Nos polices sont adaptées à vos besoins spécifiques, que vous soyez une petite entreprise locale ou une compagnie aérienne internationale.</p>

                    <p><b>•	Gestion des Sinistres Efficace : </b>En cas de sinistre, notre équipe réactive se mobilise rapidement pour vous aider à reprendre vos activités normales le plus rapidement possible.</p>

                    <p>X-RE est votre partenaire de confiance pour toutes vos besoins d'assurance aérienne. Volons ensemble vers un avenir plus sûr. Contactez-nous aujourd'hui pour discuter de vos besoins en assurance aérienne. Votre sécurité est notre priorité.</p>
                   
                   


>
                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="single-blog-wraper mb-30  wow fadeInUp">
                  <div className="blog-video-img text-center position-relative">
                    {/*<img src={blogimg2} alt="blog-img" />
                    <div className="blog-video-icon">
                      <div
                        className="blog-popup-video"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fa fa-play"></i>
                      </div>
</div>*/}
                  </div>
                  <div className="single-blog-content">
                    {/*<div className="blog-date">25 jan 2008</div>*/}
                    <h4>
                    <div className="post-title">
                    Crédit, caution et risque politique
                      </div>
                     </h4>

                    <p>
                    Dans le monde complexe des affaires d'aujourd'hui, les risques financiers et politiques peuvent surgir à tout moment, compromettant la stabilité de votre entreprise. C'est pourquoi chez X-RE, nous vous proposons des solutions complètes de crédit, de caution et de gestion des risques politiques pour vous permettre de prospérer dans un environnement imprévisible.
                    <p><b> Nos Solutions : </b></p>
                    <p><b>•	Assurance-Crédit : </b>Protégez votre entreprise contre les pertes dues aux impayés et aux défauts de paiement de vos clients. Notre assurance-crédit vous donne la confiance nécessaire pour étendre vos activités sans compromettre votre stabilité financière.</p>
                    </p>

                    <p><b>•	Caution et Garanties : </b> 
                    Simplifiez vos transactions commerciales en offrant à vos partenaires commerciaux des garanties solides et crédibles. Nous offrons une gamme variée de produits de caution pour répondre à vos besoins spécifiques.
                    </p>
                    <p><b>•	Gestion des Risques Politiques : </b>Les bouleversements politiques et les changements de réglementation peuvent mettre en péril vos opérations internationales. Notre assurance des risques politiques vous protège contre les pertes liées à des événements imprévisibles tels que les conflits, les nationalisations ou les restrictions commerciales.
                    </p>
                  

                    <p><b>Pourquoi Choisir X-RE ?</b></p>
                     
                    <p><b>
                    •	Expertise Inégalée : </b>Notre équipe possède une connaissance approfondie des marchés financiers et politiques internationaux. Nous sommes à la pointe des tendances et des développements, ce qui nous permet de vous offrir les meilleures solutions.</p>
                    <p><b>
                    •	Personnalisation : </b>Chez X-RE, nous ne proposons pas de solutions à la taille unique. Nous collaborons avec vous pour élaborer des programmes sur mesure qui répondent précisément à vos besoins.
                    </p>
                    <p>
                    <b>•	Accompagnement Continu : </b>Notre engagement envers nos clients ne se limite pas à la souscription d'une police. Nous restons à vos côtés tout au long de la durée de votre contrat, vous conseillant sur la gestion des risques et intervenant en cas de sinistre.
                    </p>

                    <p>X-RE est le partenaire de confiance dont vous avez besoin pour naviguer dans le monde complexe des risques financiers et politiques. Nous vous permettons de prendre des décisions éclairées et de vous développer en toute confiance. Contactez-nous aujourd'hui pour en savoir plus sur la façon dont X-RE peut sécuriser votre entreprise. Votre tranquillité d'esprit est notre priorité.</p>

                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="single-blog-wraper mb-30  wow fadeInUp">
                  <div className="blog-video-img text-center position-relative">
                    {/*<img src={blogimg2} alt="blog-img" />
                    <div className="blog-video-icon">
                      <div
                        className="blog-popup-video"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fa fa-play"></i>
                      </div>
                    </div>*/}
                  </div>
                  <div className="single-blog-content">
                    {/*<div className="blog-date">25 jan 2008</div>*/}

                    <h4>
                    <div className="post-title">
                    Cyber Risque
                      </div>
                   
                      
                    </h4>
                    <p>
                    <p><b>Protégez Votre Entreprise Contre les Cyber Risques Avec X-RE</b></p>
                    Avec l'essor rapide de la technologie, les entreprises sont de plus en plus exposées aux cyberattaques. Chez X-RE, nous comprenons l'importance de protéger votre entreprise contre les cyber risques qui peuvent causer des perturbations majeures et compromettre la sécurité de vos données. C'est pourquoi nous vous offrons des solutions de pointe pour vous aider à renforcer votre cyber résilience.
                    <p><b> Nos Solutions </b>  </p>

                    <p><b>•	Cyber Assurance : </b>Notre assurance cyber vous protège contre les pertes financières résultant de cyberattaques, de violations de données et de perturbations liées à la sécurité informatique. Nous couvrons les coûts de récupération des données, les pertes d'exploitation et les responsabilités liées aux violations de données.
                    </p>
                   
                    </p>
                    <p><b>•	Évaluation des Risques : </b> 
                    Notre équipe d'experts effectue des évaluations approfondies des vulnérabilités de votre entreprise pour identifier les faiblesses potentielles. Nous vous aidons à renforcer votre posture de sécurité et à réduire les risques.
                    </p>
                    <p><b>•	Formation en Cyber Sécurité : </b>La formation de votre personnel est essentielle pour prévenir les cyberattaques. Nous proposons des programmes de formation en cybersécurité pour sensibiliser vos employés et les aider à reconnaître et à éviter les menaces.
                    </p>
                  

                    <p><b>Pourquoi Choisir X-RE ?</b></p>
                     
                    <p><b>
                    •	Expertise de Pointe : </b>Notre équipe de spécialistes en cybersécurité surveille en permanence les tendances et les menaces émergentes pour garantir que nos solutions sont à jour et efficaces.</p>
                    <p><b>
                    •	Personnalisation : </b>Chaque entreprise est unique. Nous travaillons avec vous pour concevoir des solutions qui correspondent précisément à vos besoins en matière de cybersécurité.
                    </p>
                    <p><b>
                    •	Réponse Rapide : </b>En cas de cyberattaque, notre équipe d'intervention d'urgence est prête à agir rapidement pour minimiser les dommages et restaurer votre entreprise.
                    </p>

                    <p>Protégez votre entreprise contre les cyber risques avec X-RE. Nous sommes votre partenaire de confiance pour une cybersécurité robuste. Ne laissez pas votre entreprise être vulnérable aux menaces en ligne. Contactez-nous dès aujourd'hui pour en savoir plus sur nos solutions de cybersécurité complètes. Votre tranquillité d'esprit est notre priorité absolue.</p>
                   
             </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="single-blog-wraper mb-30  wow fadeInUp">
                  <div className="blog-video-img text-center position-relative">
                    {/*<img src={blogimg2} alt="blog-img" />
                    <div className="blog-video-icon">
                      <div
                        className="blog-popup-video"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fa fa-play"></i>
                      </div>
</div>*/}
                  </div>
                  <div className="single-blog-content">
                    {/*<div className="blog-date">25 jan 2008</div>*/}
                    <h4>
                      
                    <div className="post-title">
                    Ingenierie Et Gestion Des Risques de Projets avec X-RE
                    </div>                    
                      
                    </h4>
                    <p>
                    <p><b> Gestion des Risques d'Ingénierie et de Projet avec X-RE </b>  </p>
                    Chez X-RE, nous comprenons les défis complexes auxquels sont confrontés les projets d'ingénierie et de construction. Les risques inhérents à ces projets peuvent avoir un impact financier significatif et entraîner des retards importants. C'est pourquoi nous offrons des solutions spécialisées pour aider à gérer ces risques de manière efficace et proactive.
                    <p><b> Protect Your Business Against Cyber Risks With X-RE  </b>  </p>
                    <p><b> Nos Solutions </b>  </p>

                    <p><b>•	Assurance des Projets d'Ingénierie : </b>: Notre assurance couvre les risques associés à la construction et à l'ingénierie, y compris les retards, les pertes financières et les dommages matériels. Vous pouvez avoir l'assurance que votre projet est protégé contre les imprévus.</p>
                   
                    </p>

                    <p><b>•	Évaluation des Risques : </b> 
                    Avant de commencer un projet, notre équipe d'experts évalue en profondeur les risques potentiels. Cela vous permet de prendre des décisions éclairées et de mettre en place des mesures d'atténuation.

                    </p>
                    <p><b>•	Gestion de Projet : </b>Nous offrons une expertise en gestion de projet pour vous aider à superviser et à coordonner efficacement toutes les phases de votre projet d'ingénierie. Cela inclut la gestion des coûts, des délais et des ressources.
                    </p>

                    <p><b>Pourquoi Choisir X-RE ?</b></p>
                     
                    <p><b>
                    •	Expertise Technique : </b>: Notre équipe possède une connaissance approfondie des défis techniques liés à l'ingénierie et à la construction, ce qui nous permet de fournir des solutions adaptées.
                    </p>
                    <p><b>
                    •	Personnalisation : </b>Nous adaptons nos solutions à la taille et à la complexité de votre projet, que ce soit une petite installation ou un projet d'infrastructure majeur.
                    </p>
                    <p><b>
                    •	Réponse Rapide : </b>En cas d'incident ou de réclamation, notre équipe est disponible pour vous offrir une assistance rapide et professionnelle.
                   
                    </p>

                    <p>Protégez votre projet d'ingénierie contre les risques potentiels avec X-RE. Nous sommes là pour vous aider à réussir en toute confiance. Contactez-nous dès aujourd'hui pour discuter de vos besoins spécifiques en matière de gestion des risques d'ingénierie et de projet. Votre succès est notre priorité.</p>

                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="single-blog-wraper mb-30  wow fadeInUp">
                  <div className="blog-video-img text-center position-relative">
                    {/*<img src={blogimg2} alt="blog-img" />
                    <div className="blog-video-icon">
                      <div
                        className="blog-popup-video"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fa fa-play"></i>
                      </div>
</div>*/}
                  </div>
                  <div className="single-blog-content">
                    {/*<div className="blog-date">25 jan 2008</div>*/}
                    <h4>
                    <div className="post-title">
                    Gestion des Risques liés aux Énergies Renouvelables avec X-RE
                      </div> 
                      
                      
                    </h4>
                    <p>
                    Chez X-RE, nous croyons en un avenir alimenté par des énergies plus propres et plus durables. Cependant, la transition vers les énergies renouvelables n'est pas sans risques. C'est pourquoi nous offrons des solutions de gestion des risques spécialement conçues pour l'industrie des énergies renouvelables.
                    
                    <p><b> Nos Solutions </b>  </p>

                    <p><b>•	Assurance Éolienne et Solaire : </b>Nous proposons des solutions d'assurance sur mesure pour les parcs éoliens et solaires, couvrant les dommages matériels, les pertes d'exploitation et les risques de responsabilité civile.

                    </p>
                    </p>

                    <p><b>
                    •	Analyse des Risques : </b> 
                    Notre équipe d'experts effectue une analyse approfondie des risques liés à vos projets d'énergies renouvelables. Cela inclut l'évaluation des risques environnementaux, techniques et de performance.

                    </p>
                    <p><b>
                    •	Gestion des Perturbations : </b> Les énergies renouvelables dépendent souvent de conditions météorologiques et environnementales. Nous vous aidons à élaborer des plans de gestion des perturbations pour minimiser les interruptions d'exploitation.
                    </p>
                  

                    <p><b>•	Couverture de Responsabilité Environnementale : </b>Protégez-vous contre les risques de pollution et de dommages environnementaux avec notre assurance de responsabilité environnementale. </p>
                    
                    <p><b>Pourquoi Choisir X-RE ?</b></p>
                     
                    <p><b>
                    •	Expertise Sectorielle : </b>Notre connaissance approfondie de l'industrie des énergies renouvelables nous permet de comprendre les risques spécifiques auxquels vous êtes confrontés.
                    </p>
                    <p><b>
                    •	Innovation : </b>Nous sommes engagés dans le développement de nouvelles solutions d'assurance adaptées aux technologies émergentes et aux évolutions du secteur.
                    </p>
                    <p><b>

                    •	Durabilité : </b>X-RE s'engage à soutenir la transition vers des énergies plus propres en offrant des solutions qui encouragent le développement durable.
                    </p>

                    <p><b>•	Service Personnalisé : </b>Nous travaillons en étroite collaboration avec vous pour élaborer des programmes d'assurance adaptés à vos besoins spécifiques.</p>

                    <p>Protégez vos investissements dans les énergies renouvelables avec X-RE. Notre objectif est de vous permettre de poursuivre vos projets en toute confiance, sachant que vos actifs sont protégés. Contactez-nous aujourd'hui pour discuter de vos besoins en matière de gestion des risques liés aux énergies renouvelables. Votre succès durable est notre priorité.</p>

                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="single-blog-wraper mb-30  wow fadeInUp">
                  <div className="blog-video-img text-center position-relative">
                    {/*<img src={blogimg2} alt="blog-img" />
                    <div className="blog-video-icon">
                      <div
                        className="blog-popup-video"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fa fa-play"></i>
                      </div>
</div>*/}
                  </div>
                  <div className="single-blog-content">
                    {/*<div className="blog-date">25 jan 2008</div>*/}
                    <h4>
                    <div className="post-title">
                      Gestion des Risques Maritimes avec X-RE
                      </div> 
                    </h4>
                    <p>
                    Chez X-RE, nous comprenons que l'industrie maritime est confrontée à une variété de risques complexes. C'est pourquoi nous offrons des solutions de gestion des risques maritimes pour vous aider à naviguer en toute confiance dans un monde maritime en constante évolution.
                    
                    <p><b> Nos Solutions </b>  </p>

                    <p><b>•	Assurance Maritime : </b>Nous proposons une gamme complète d'assurances maritimes, notamment l'assurance des navires, la protection et indemnisation des créanciers, ainsi que la couverture de la responsabilité civile maritime.
                    </p>
                    </p>

                    <p><b>
                    •	Gestion des Risques : </b> 
                    Notre équipe d'experts réalise des évaluations complètes des risques pour votre entreprise maritime. Cela comprend l'identification des risques liés aux conditions météorologiques, à la navigation, à la sécurité des équipages et à la conformité réglementaire.
                    </p>
                    <p><b>
                    •	Protection de la Chaîne d'Approvisionnement : </b>Nous offrons des solutions pour protéger votre chaîne d'approvisionnement maritime contre les perturbations, les retards et les risques opérationnels.
                    </p>
                  
                    <p><b>•	Assurance de Responsabilité Civile Maritime : </b>Protégez-vous contre les réclamations de tiers pour dommages matériels, pollution ou blessures corporelles résultant de vos opérations maritimes.
                    </p>
                    <p><b>Pourquoi Choisir X-RE ?</b></p>
                     
                    <p><b>
                    •	Expertise Approfondie : </b>Notre équipe possède une connaissance inégalée de l'industrie maritime, ce qui nous permet de concevoir des programmes d'assurance sur mesure.</p>
                    <p><b>
                    •	Réactivité : </b>Nous comprenons l'urgence dans l'industrie maritime. En cas de sinistre, notre équipe réagit rapidement pour minimiser les perturbations.
                    </p>
                    <p><b>

                    •	Conformité Réglementaire : </b>Nous vous aidons à vous conformer aux réglementations maritimes nationales et internationales en constante évolution.
                    </p>

                    <p><b>
                    •	Service Client Exceptionnel : </b>: Chez X-RE, nous sommes déterminés à fournir un service client exceptionnel. Nous travaillons en étroite collaboration avec vous pour répondre à vos besoins spécifiques.
                    </p>

                    <p>Naviguez en toute sécurité avec X-RE à vos côtés. Contactez-nous dès aujourd'hui pour discuter de vos besoins en gestion des risques maritimes. Votre succès en mer est notre priorité.</p>

                  </div>
                </div>
              </div>
              <div className="col-xl-12">
                <div className="single-blog-wraper mb-30  wow fadeInUp">
                  <div className="blog-video-img text-center position-relative">
                    {/*<img src={blogimg2} alt="blog-img" />
                    <div className="blog-video-icon">
                      <div
                        className="blog-popup-video"
                        onClick={() => setOpen(true)}
                      >
                        <i className="fa fa-play"></i>
                      </div>
</div>*/}
                  </div>
                  <div className="single-blog-content">
                    {/*<div className="blog-date">25 jan 2008</div>*/}
                    <h4>
                    <div className="post-title">
                    Expertise en Assurance de la Ligne Financière
                      </div> 
                    </h4>
                    <p>
                    Chez X-RE, notre expertise en assurance de la ligne financière est au cœur de notre engagement envers nos clients. Nous comprenons les défis complexes auxquels sont confrontées les institutions financières et les entreprises dans ce domaine. C'est pourquoi nous avons développé des solutions d'assurance sur mesure pour répondre à leurs besoins uniques.
                    <p><b>Notre Gamme de Produits en Ligne Financière</b>  </p>

                    <p><b>•	Responsabilité des Administrateurs et Dirigeants (D&O) : </b>Protégez les actifs personnels des dirigeants et administrateurs tout en assurant la continuité des opérations en cas de litige.

                    </p>
                    </p>

                    <p><b>
                    
                    •	Fiducies et Cautionnements : </b> 
                    Obtenez une protection essentielle pour les activités de fiducie et de cautionnement, garantissant ainsi la confiance de vos clients et partenaires commerciaux.
                    </p>
                    <p><b>
                    •	Assurance Fraude Financière : </b>Prévenez et atténuez les risques de fraude financière grâce à notre assurance spécialisée.
 
                    </p>
                                      

                    <p><b>Marine Liability Insurance:  </b>Protect yourself against third-party claims for property damage, pollution or personal injury resulting from your marine operations. 
                    </p>
                    <p><b>Pourquoi Choisir X-RE pour la Ligne Financière ?</b></p>
                     
                    <p><b>
                    
                    •	Expertise Inégalée : </b>Nos experts en assurance de la ligne financière sont parmi les meilleurs de l'industrie, apportant des connaissances approfondies et une compréhension des risques financiers uniques.
                    </p>
                    <p><b>

                    •	Solutions Personnalisées : </b>Chaque entreprise a des besoins différents. Chez X-RE, nous personnalisons nos solutions pour répondre aux besoins spécifiques de nos clients.
                    </p>
                    <p><b>


                    •	Service Exceptionnel : </b>Nous nous engageons à fournir un service exceptionnel à chaque étape, de la consultation initiale à la gestion des réclamations.
                    </p>

                    <p><b>
                    •	Gestion Rigoureuse des Risques : </b>Notre approche de la gestion des risques vous aide à identifier et à atténuer les vulnérabilités potentielles au sein de votre entreprise.
                    </p>

                    <p>Nous croyons que la tranquillité d'esprit financière est un élément essentiel de la réussite des entreprises. Confiez vos besoins en assurance de la ligne financière à X-RE et concentrez-vous sur la croissance de votre entreprise en toute confiance.</p>











                    {/* <div className="post-info">
                      <a href="#">by admin</a>
                      <a href="#">1 comments</a>
                    </div>*/}
                  </div>
                </div>
              </div>
              {/*<div className="post-info">
                      <a href="#">by admin</a>
                      <a href="#">1 comments</a>
                  </div>*/}
                 
                  
                




















                
              
             

   

                   {/* <div className="post-info">
                      <a href="#">by admin</a>
                      <a href="#">1 comments</a>
                    </div>*/}
                  
              {/*  <div className="col-xl-12">
                <div className="merox-pagination mt-50">
                  <nav className="pagination">
                    <div className="nav-links">
                      <span
                        aria-current="page"
                        className="page-numbers current"
                      >
                        1
                      </span>
                      <a className="page-numbers" href="#">
                        2
                      </a>
                      <a className="next page-numbers" href="#">
                        <i className="fa fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </nav>
                </div>
              </div>*/}
            </div>
      
                
              
            
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogList;
