import React from "react";
import Breadcrumb1 from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Testimonial2 from "../components/Testimonial2";
import Header from "../components/Header";

const Technology = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb1 name="Technologie" />
        <Testimonial2 />
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default Technology;
