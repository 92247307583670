import React from "react";
import serv1 from "../img/services/1.png";
import serv2 from "../img/services/2.png";
import serv3 from "../img/services/3.png";
import servbg from "../img/services/services-bg.jpg";
import { Link } from "react-router-dom";
const Services = () => {
  return (
    <>
      <div
        id="merox-services-area"
        className="merox-services-area pt-130 pb-130"
      >
        <div className="merox-services-area-img">
          <img src={servbg} alt="img" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-4 offset-xl-1 col-lg-4 order-2 wow fadeInRight">
              <div className="section-title lite">
                <h4>X-RE Solutions</h4>
                
                <h3>Nos Solutions</h3>
              </div>
            </div>
            <div className="col-xl-7 col-lg-8 wow fadeInLeft">
              <div className="services-box">
                <div className="single-services">
                   {/* <img src={serv1} alt="icon" />*/}
                  <div className="services-content-box">
                  <Link to={`${process.env.PUBLIC_URL + "/Property"}`} className="post-title">
                    
                  <h3>Réassurance & dommages</h3>
                  </Link>
                    
                   
                  </div>
                </div>

                <div className="single-services">
                  {/* <img src={serv2} alt="icon" />*/}
                  <div className="services-content-box">
                  <Link to={`${process.env.PUBLIC_URL + "/Life-Risk"}`} className="post-title">
                    <h3>Réassurance Vie & Santé</h3>
                    </Link>       
                  </div>
                </div>
                

                <div className="single-services">
                  {/* <img src={serv3} alt="icon" />*/}
                  <div className="services-content-box">

                   
                    <Link to={`${process.env.PUBLIC_URL + "/Global-Clients"}`} className="post-title">
                    <h3>Clients & Solutions Mondiaux</h3>
                    </Link> 
                  </div>
                </div>
                <div className="single-services">
                  {/*<img src={serv2} alt="icon" />*/}

                  
                  <div className="services-content-box">
                  <Link to={`${process.env.PUBLIC_URL + "/Reinsurance-Solutions"}`} className="post-title">
                  <h3>Solutions de Réassurance</h3>
                    </Link> 
                    
                    
                  </div>
                </div>
                <div className="single-services">
                   {/*<img src={serv2} alt="icon" />*/}

                  <div className="services-content-box">
                  <Link to={`${process.env.PUBLIC_URL + "/Risk-management"}`} className="post-title">
                  <h3>Gestion des risques</h3>
                    </Link> 
                  
                   
                  </div>
                </div>
                {/* <div className="single-services">
                 <img src={serv2} alt="icon" />
                  <div className="services-content-box">
                    <h3>Corporate Solutions</h3>
                  </div>
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
