import React from "react";
import Breadcrumb1 from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Counter from "../components/Counter";
import About2 from "../components/About2";
import Header from "../components/Header";

const About = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb1 name="Qui Sommes-nous ?" />
        <About2 />
        <Counter />
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default About;
