import React from "react";
import Breadcrumb1 from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Guidelines2 from "../components/Guidelines2";
import Header from "../components/Header";

const Guidelines = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb1 name="Des lignes directrices" />
        <Guidelines2 />
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default Guidelines;