import React from "react";
import abimg1 from "../img/about/Logo XRE@2x.png";
import { Link } from "react-router-dom";
const About = () => {
  return (
    <>
      <div id="merox-about-area" className="merox-about-area mt-100 mb-70">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-6 col-md-12 wow fadeInLeft">
              <div className="about-img-area">
                <img src={abimg1} alt="about" />
              </div>
            </div>
            <div className="col-xl-12 col-lg-15 col-md-12 offset-xl-1 wow fadeInRight">
              <div className="section-title">
                
                <h3>
                  A Propos de <span>X-RE</span> AFRICA
                </h3>
              </div>
              <div className="about-content">
                <p>
                <b>Notre histoire : </b> X-RE se développe pour devenir un leader de l'industrie de la réassurance.
                Notre voyage est marqué par l'innovation constante et la recherche inlassable de moyens d'améliorer
                le paysage de la réassurance. L'histoire de X-RE est celle d'une transformation,
                d'une vision audacieuse et de progrès continus.
                </p>
                <p>
                <b>Notre mission:</b> Chez X-RE, notre mission est profondément ancrée dans notre nom : Réassurance, Excellence, technologie.
                Notre engagement est de vous apporter une tranquillité d'esprit inégalée grâce à des solutions de réassurance adaptées à l'ère numérique.
                Nous croyons que la réassurance ne devrait pas seulement vous protéger, mais aussi autonomiser tous vos processus.
                </p>
                <p>
                  <b>Nos valeurs :</b> Les valeurs de X-RE sont au cœur de tout ce que nous faisons.
                  <strong> Rassurer la Clientèle</strong> est notre devise, et nous la poursuivons avec dévouement.
                  Notre engagement envers l'intégrité, l'innovation, la collaboration et la satisfaction
                  client nous guide dans chaque interaction et chaque solution que nous proposons.
                </p>
                <p>
                  <b>Notre Approche Technologique :</b> Ce qui distingue X-RE, c'est notre approche résolument axée sur la technologie.
                  Avec notre plateforme HYPERION 2.0, nous donnons vie à la réassurance du futur.
                  Nous exploitons les données et l'automatisation pour offrir des solutions plus rapides,
                  plus précises et plus personnalisées.
                </p>
                <p>
                  <b> Notre Engagement Durable :</b> X-RE reconnaît l'importance de la durabilité dans le monde d'aujourd'hui.
                  Nous nous engageons à soutenir des pratiques commerciales responsables qui contribuent à un avenir meilleur pour tous.
                </p>
                    <p>
                    <b>Nous Contacter : </b>Chez X-RE, nous croyons en la puissance de la communication.
                    Si vous avez des questions, des besoins de réassurance spécifiques ou si vous cherchez à collaborer avec des visionnaires de l'industrie,
                    n'hésitez pas à nous contacter. Notre équipe est prête à vous accompagner dans chaque étape de votre parcours vers une réassurance exceptionnelle.
                    Bienvenue chez X-RE, où l'innovation rencontre la réassurance, et où la confiance rencontre la technologie pour un avenir plus sûr et plus prometteur
                      </p>
                {/*<ul className="about-content-list">
                  <li>
                    <i className="fa fa-check"></i> There are many variations of
                    passages of Lorem
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Majority have suffered
                    alteration in some
                  </li>
                  <li>
                    <i className="fa fa-check"></i> Sure there isn't anything
                    embarrassing hidden in the
                  </li>
                  <li>
                    <i className="fa fa-check"></i> All the Lorem Ipsum
                    generators on the Internet tend to
                  </li>
  </ul>*/}
                {/* <Link to="" className="btn btn-type-4">
                  about us
                </Link>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
