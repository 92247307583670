import React from "react";
import Mainslider from "../components/Mainslider";
import About from "../components/About";
import Services from "../components/Services";
import Team from "../components/Team";
import Testimonial from "../components/Testimonial";
import Blog from "../components/Blog";
import Client from "../components/Client";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";
import ContactContent from "../components/ContactContent";
const Home = () => {
  return (
    <>
      <Header />
      <Header />
      <main>
        <Mainslider />

        <About />
        <Services />
        <Team />
        <Testimonial />
        <ContactContent />
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default Home;
