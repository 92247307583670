import React from "react";
import Breadcrumb1 from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Property from "../components/Property";
import Scrolltop from "../components/Scrolltop";
import Header from "../components/Header";

const property = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb1 name="Réassurance de dommages" />
        <Property />
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default property;
