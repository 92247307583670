import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
const Testimonial2 = () => {
  const options = {
    loop: true,
    autoplay: true,
    smartSpeed: 1000,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };
  return (
    <>
      <div
        id="merox-testimonial-area"
        className="merox-testimonial-area mt-100 mb-100 wow fadeInUp"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title">
              <h3>Nos principes</h3>
                <h4>Principes Directeurs</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="testimonial-wraper">
                <OwlCarousel className="owl-theme owl-carousel" {...options}>
                  <div className="single-testimonial">
                    
                    <div className="testimonial-content">
                      <p>
                      Chez X-RE, nos principes directeurs sont les fondations sur lesquelles
                      nous bâtissons notre entreprise et servons nos clients. Ils reflètent notre
                      engagement envers l'excellence, la responsabilité et l'innovation,
                      tout en incarnant notre promesse de Rassurer la Clientèle. Voici nos principes directeurs clés :
                      </p>
                      
                      
                    </div>
                  </div>
                  <div className="single-testimonial">
                    
                    <div className="testimonial-content"></div>
                    <p>
                  <b>Intégrité Inébranlable :</b> L'intégrité est au cœur de tout ce que nous faisons.
                  Nous sommes engagés à maintenir les normes les plus élevées en matière d'éthique professionnelle,
                  de transparence et d'honnêteté. Nous rassurons nos clients en étant un partenaire de confiance.
                  </p>
                  </div>
                  <div className="single-testimonial">
                    
                    <div className="testimonial-content"></div>
                    <p>
                  <b>Excellence Opérationnelle </b> Nous visons l'excellence dans toutes nos opérations. De la souscription à la gestion des sinistres,
                  nous nous efforçons d'offrir des solutions de réassurance exceptionnelles qui dépassent les attentes.
                  </p>
                  </div>
                  <div className="single-testimonial">
                    
                    <div className="testimonial-content"></div>
                    <p>
                  <b>Innovation Pionnière </b> L'innovation est notre moteur. Nous croyons en la
                  puissance de la technologie pour révolutionner l'industrie de la réassurance. Nous investissons
                  dans la recherche et le développement pour rester à la pointe et anticiper les besoins changeants de nos clients.
                  </p>
                  </div>

                  <div className="single-testimonial">
                    
                    <div className="testimonial-content">
                      <p>
                      <b>collaboration active :</b> Nous croyons que les meilleurs résultats découlent d'une collaboration étroite avec nos clients,
                      partenaires et collègues. En travaillant ensemble, nous construisons des solutions sur mesure qui apaisent les préoccupations de nos clients.
                      </p>

                    </div>
                  </div>

                  <div className="single-testimonial">
                    
                    <div className="testimonial-content">
                      <p>
                      <b>Durabilité Responsable : </b>Nous nous engageons à pratiquer une réassurance responsable qui prend en compte
                      les considérations environnementales, sociales et de gouvernance. Nous visons à créer un impact positif sur les communautés que nous servons.
                      </p>

                    </div>
                  </div>

                  <div className="single-testimonial">
                    
                    <div className="testimonial-content">
                    <p>
                    <b>Personnalisation Client :</b> Chaque client est unique, et nous traitons chaque besoin de manière personnalisée.
                    Notre objectif est de comprendre profondément les besoins de chaque client pour offrir des solutions qui rassurent.
                  </p>
                  </div>
                  </div>

                  <div className="single-testimonial">
                    
                  <div className="testimonial-content">
                      <p>
                      <b>Transcender les Attentes : </b> 
                      Nous ne visons pas seulement à répondre aux attentes de nos clients, mais à les surpasser.
                      Notre objectif est de laisser une impression durable de satisfaction et de confiance.
                      </p>
                  </div>
                  </div>

                  <div className="single-testimonial">
                    
                    <div className="testimonial-content">
                      <p>Ces principes directeurs guident notre entreprise au quotidien, nous aidant à façonner l'avenir de la réassurance.
                        Nous croyons que c'est en adhérant à ces principes que nous pouvons continuer à <strong>Rassurer la Clientèle</strong>avec excellence et innovation.</p>
                  </div>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial2;
