import React from "react";
import Map from "./Map";
import icon1 from "../img/contact/icon-1.png";
import icon2 from "../img/contact/icon-2.png";
import icon3 from "../img/contact/icon-3.png";
const ContactContent = () => {
  return (
    <>
      <div id="merox-contact-area" className="merox-contact-area mt-100 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title">
                <h4>contacts</h4>
                <h3>contactez-nous</h3>
              </div>
            </div>
          </div>
        </div>
        {/*
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 pr-30">
              <div className="appnox-contact-form-area">
                <form
                  className="appnox-contact-form"
                  id="ajax-contact"
                  action="mail.php"
                  method="post"
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="Name *"
                        />
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="E-mail *"
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                        <input
                          className="form-control"
                          type="text"
                          name="subject"
                          placeholder="Subject"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-12">
                        <textarea
                          className="form-control"
                          name="message"
                          cols="30"
                          rows="8"
                          placeholder="Message"
                        ></textarea>
                        <button type="submit" className="btn btn-type-1">
                          Send Message
                        </button>
                      </div>
                    </div>
                    <p className="form-message float-left mt-15"></p>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
              <Map />
            </div>
          </div>
        </div>*/}
  </div>
      <div
        id="merox-contact-info-area"
        className="merox-contact-info-area mb-70"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="contact-info-box">
                <div className="contact-info-icon">
                  <img src={icon1} alt="img" />
                </div>
                <ul className="contact-info">
                  <li>+225 2722236292</li>
                  <li>+225 0 574 408 383 / 0 778 625 372</li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="contact-info-box">
                <div className="contact-info-icon">
                  <img src={icon2} alt="img" />
                </div>
                <ul className="contact-info">
                  <li>Immeuble Cube, 2e étage</li>
                  <li>Angré 9 ème tranche, Abidjan</li>
                </ul>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="contact-info-box">
                <div className="contact-info-icon">
                  <img src={icon3} alt="img" />
                </div>
                <ul className="contact-info">
                  <li>
                    <a href="mailto:merox@web.com">info@x-re-africa.com</a>
                  </li>
                  <li>
                    <a href="mailto:sales@website.com">emmanuel.abbe@x-re-africa.com</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactContent;
