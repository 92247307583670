import React from "react";
import details from "../img/blog/details.jpg";
import details1 from "../img/blog/details-1.jpg";
import wdg1 from "../img/wdg/wdg-img-1.jpg";
import wdg2 from "../img/wdg/wdg-img-2.jpg";
import wdg3 from "../img/wdg/wdg-img-3.jpg";
import com1 from "../img/blog/comments1.png";
import com2 from "../img/blog/comments2.png";
import { Link } from "react-router-dom";
const BlogDetails = () => {
  return (
    <>
      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-8 pr-30">
              <div className="merox-blog-details-wraper">
                <div className="blog-details-content">
                  <div className="blog-details-img">
                    <img src={details} alt="img" />
                  </div>
                  <div className="blog-date">14 nov 2019</div>
                  <h4>
                    in spring dumont opens mixed reality on experience popular
                    belief
                  </h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the have and majority have suffered teration
                    some form, by injected humour, and omised words which don't
                    look even slightly believable. There are many of it passages
                    of Lorem’s Ipsum available, but the
                  </p>
                  <figure>
                    <img src={details1} alt="img" />
                  </figure>
                  <h3>we’re fortunate to work with worldwide clients</h3>

                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable of a page when this looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters
                  </p>

                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable of a page when this looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English.
                  </p>

                  <blockquote className="block-quote">
                    <p>
                      industry.has been the industry's standard dummy text ever
                      since the 1500s, when an unk nown printer took a galley of
                      type and and scrales
                    </p>
                    <cite>mr. robul islam</cite>
                  </blockquote>

                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable of a page when this looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters
                  </p>

                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable of a page when this looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters, as opposed to
                    using 'Content here, content here', making it look like
                    readable English.
                  </p>
                </div>
                <div className="blog-post-tag">
                  <span>post tags:</span>
                  <Link to="">awesome</Link>
                  <Link to="">template</Link>
                  <Link to="">ui/ux</Link>
                </div>
                <div className="recent-comments-area mt-50">
                  <div className="post-heading">
                    <h3>3 Comments</h3>
                  </div>
                  <div className="latest-comments">
                    <ul>
                      <li>
                        <div className="comments-box">
                          <div className="comments-avatar">
                            <img src={com1} alt="img-1" />
                          </div>
                          <div className="comments-text">
                            <div className="avatar-name-date">
                              <h5>merox</h5>
                              <span>Dec 08 2022</span>
                            </div>
                            <p>
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since t he 1500s, when an
                              unknown
                            </p>
                            <Link to="">Reply</Link>
                          </div>
                        </div>
                        <ul className="comments-reply">
                          <li>
                            <div className="comments-box">
                              <div className="comments-avatar">
                                <img src={com2} alt="img-2" />
                              </div>
                              <div className="comments-text">
                                <div className="avatar-name-date">
                                  <h5>Amron Morg</h5>
                                  <span>Dec 08 2022</span>
                                </div>
                                <p>
                                  industry. Lorem Ipsum has been the industry's
                                  standard dummy text ever since t he
                                </p>{" "}
                                <Link to="">
                                  Reply{" "}
                                  <i className="fa fa-long-arrow-right"></i>
                                </Link>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <div className="comments-box">
                          <div className="comments-avatar">
                            <img src={com1} alt="img-3" />
                          </div>
                          <div className="comments-text">
                            <div className="avatar-name-date">
                              <h5>merox</h5>
                              <span>Dec 08 2022</span>
                            </div>
                            <p>
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since t he 1500s, when an
                              unknown
                            </p>{" "}
                            <Link to="">Reply</Link>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="post-comments-form-area">
                  <div className="post-heading">
                    <h3>leave a reply</h3>
                  </div>
                  <form className="post-comments-form">
                    <div className="row">
                      <div className="col-xl-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name *"
                        />
                      </div>
                      <div className="col-xl-6">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="E-mail *"
                        />
                      </div>
                      <div className="col-xl-12">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Subject"
                        />
                      </div>
                      <div className="col-xl-12">
                        <textarea
                          name="comments"
                          className="form-control"
                          cols="30"
                          rows="10"
                          placeholder="Your Comment"
                        ></textarea>
                      </div>

                      <div className="col-xl-12">
                        <button className="btn btn-type-1" type="submit">
                          post comment
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4">
              <div className="single-sid-wdg sm-mt-30">
                <form action="#" className="wdg-search-form">
                  <input type="text" placeholder="search " />
                  <button className="submit-btn" type="submit">
                    <i className="fa fa-search"></i>
                  </button>
                </form>
              </div>
              <div className="single-sid-wdg">
                <div className="widget_recent_entries">
                  <h3>popular posts</h3>
                  <div className="single-wdg-post">
                    <div className="wdg-post-img">
                      <Link to="">
                        <img
                          src={wdg1}
                          alt="blog thumbnail"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="wdg-post-content">
                      <h5>
                        <Link to="">
                          How to build a company culture of success
                        </Link>
                      </h5>
                      <span>Sep 30, 2019</span>
                    </div>
                  </div>

                  <div className="single-wdg-post">
                    <div className="wdg-post-img">
                      <Link to="">
                        <img
                          src={wdg2}
                          alt="blog thumbnail"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="wdg-post-content">
                      <h5>
                        <Link to="">
                          Investment Update, Successful people ask
                        </Link>
                      </h5>
                      <span>Dec 11, 2019</span>
                    </div>
                  </div>

                  <div className="single-wdg-post">
                    <div className="wdg-post-img">
                      <Link to="">
                        <img
                          src={wdg3}
                          alt="blog thumbnail"
                          className="img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="wdg-post-content">
                      <h5>
                        <Link to="">
                          Investment Update, Successful people ask
                        </Link>
                      </h5>
                      <span>Jan 15, 2019</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="single-sid-wdg">
                <h3>follow us</h3>
                <ul className="wdg-follow-us">
                  <li>
                    <Link to="">
                      <i className="fa fa-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fa fa-twitter"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fa fa-linkedin"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fa fa-pinterest-p"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fa fa-instagram"></i>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="single-sid-wdg">
                <div className="widget_tag_cloud">
                  <h3>post tags</h3>
                  <div className="tagcloud">
                    <Link to="">awesome</Link>
                    <Link to="">template</Link>
                    <Link to="">ui/ux</Link>
                    <Link to="">software</Link>
                    <Link to="">develop</Link>
                  </div>
                </div>
              </div>

              <div className="single-sid-wdg">
                <div className="widget_categories">
                  <h3>categories</h3>
                  <ul>
                    <li>
                      <Link to="">gallery</Link>
                    </li>
                    <li>
                      <Link to="">business</Link>
                    </li>
                    <li>
                      <Link to="">office</Link>
                    </li>
                    <li>
                      <Link to="">design</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
