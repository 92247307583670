import React from "react";
const Team2 = () => {
  return (
    <>
      <div
        id="merox-team-area"
        className="merox-team-area mt-100 mb-70 wow fadeInUp"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title">
              <h3>NOS VALEURS : Rassurer la clientèle</h3>

                <h4>Intégrité et Transparence</h4>
                <p>
                Nous opérons avec intégrité,
                fournissant des informations transparentes pour établir et renforcer la confiance.
                </p>
                <h4>Expertise Inégalée</h4>
                <p>       
                Notre équipe possède une expertise inégalée pour
                anticiper et résoudre vos besoins en matière de réassurance.
                </p>
                <h4>Service Client de Premier Ordre :</h4>
                <p>       
                Notre engagement envers un service client de qualité supérieure
                garantit que vos préoccupations sont entendues et résolues rapidement.
                </p>
                <h4>Innovation pour la Sécurité </h4>
                <p>       
                Nous investissons continuellement dans des technologies innovantes pour renforcer la sécurité et la stabilité de vos investissements.
                </p>
                <h4>La Personnalisation pour Répondre à Vos Besoins :</h4>
                <p>       
                Chaque client est unique, c'est pourquoi nous personnalisons nos solutions de réassurance pour répondre spécifiquement à vos besoins.
                Votre entreprise est unique, et nous nous engageons à adapter nos services pour vous.
                </p>
                <p>       
                Ces déclinaisons de la valeur "Rassurer la Clientèle" démontrent notre engagement à créer un environnement de confiance, à fournir un service exceptionnel et à innover pour répondre aux besoins individuels de chaque client.
                </p>
                
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default Team2;
