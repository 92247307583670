import React from "react";
import Breadcrumb1 from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import ContactContent from "../components/ContactContent";
import Header from "../components/Header";

const Contact = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb1 name="contacts" />
        <ContactContent />
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default Contact;
