import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import blogimg1 from "../img/blog/1.jpg";
import blogimg2 from "../img/blog/2.jpg";
import blogimg3 from "../img/blog/3.jpg";
import { Link } from "react-router-dom";
const ReinsuranceSolutions = () => {
  const [isOpen, setOpen] = useState(false);

  const options = {
    loop: true,
    autoplay: true,
    animateIn: "zoomInDown",
    smartSpeed: 5000,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="snvzakfcTmY"
        onClose={() => setOpen(false)}

      />
      <div className="single-blog-content" style={{ textAlign: 'center', color: 'red', fontSize: '24px' }}>
  <h4>
    <Link to="#" className="post-title">
    Solutions de réassurance
    </Link>
  </h4>
</div>


      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
     

        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">
                <div className="blog-img">
                  {/*<img src={blogimg1} alt="blog-img" />*/}
                 
                </div>
                <div className="single-blog-content">
                   {/*<div className="blog-date">14 nov 2019</div>*/}
                  
                  <p>
                  Chez X-RE, nous croyons en la puissance de la technologie pour révolutionner le secteur de l'assurance et de la réassurance. C'est pourquoi nous avons développé HYPERION 2.0, une plateforme de pointe qui offre des possibilités inégalées en termes de gestion des primes, de gestion des sinistres, de comptabilité, de facturation et bien plus encore. Découvrez comment HYPERION 2.0 peut propulser votre entreprise vers l'avenir de l'assurance.
                  </p>
                  <p><b>Gestion des Primes Simplifiée : </b>WGrâce à HYPERION 2.0, vous pouvez gérer vos primes en toute simplicité, de l'offre de placement au contrat. Notre plateforme rationalise chaque étape du processus, vous permettant de gagner du temps et de réduire les erreurs.  </p>
                  <p><b>Gestion des Sinistres Efficace :  </b>En cas de sinistre, la réactivité est essentielle. HYPERION 2.0 vous offre des outils avancés pour une gestion des sinistres rapide et précise, ce qui signifie des clients plus satisfaits.  </p>

                  <p><b>
                  Comptabilité et Facturation Transparentes : </b>Éliminez les tracas de la comptabilité et de la facturation. Notre plateforme vous donne un contrôle total sur ces processus, avec une visibilité totale à chaque étape.  </p>

                  <p> <b>Outils Actuariels de Pointe : </b> Prenez des décisions éclairées grâce à nos outils actuariels avancés. L'analyse des risques n'a jamais été aussi précise. </p>

                  
                  <div className="post-info">
                    {/*<Link to="">by admin</Link>
                    <Link to="">0 comments</Link>*/}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">
                <div className="blog-img">
                  {/*<img src={blogimg2} alt="blog-img" />*/}
                </div>
                <div className="single-blog-content">
                  {/*<div className="blog-date">25 jan 2008</div>*/}
                  <h4>
                    {/*<Link to="" className="post-title">
                      there are many variations of passages of but
                  </Link>*/}
                  </h4>
                  
                  <p><b>Machine Learning et Statistiques : </b>HYPERION 2.0 intègre des capacités de machine learning et de statistiques avancées pour vous aider à identifier les tendances, à prédire les risques et à prendre des décisions basées sur des données probantes.</p>
                  <p><b>Sécurité de Pointe : </b>Vos données sont précieuses, c'est pourquoi nous avons intégré des mesures de sécurité de pointe pour protéger chaque point de vos opérations.</p>
                  <p><b>Assistance Personnalisée : </b>Notre équipe dévouée est là pour vous accompagner à chaque étape de l'adoption de HYPERION 2.0. Nous nous assurons que vous tirez le meilleur parti de notre technologie.</p>

                  <p>HYPERION 2.0 n'est pas seulement une plateforme, c'est un partenaire pour l'avenir de votre entreprise d'assurance. Découvrez comment nous pouvons personnaliser HYPERION 2.0 pour répondre à vos besoins spécifiques. Contactez-nous aujourd'hui pour une démonstration personnalisée et découvrez comment la technologie peut transformer votre entreprise d'assurance. Votre succès est notre priorité.</p>
                  <div className="post-info">
                    {/*<Link to="">by admin</Link>
                    <Link to="">1 comments</Link>*/}
                  </div>
                </div>
              </div>
            </div>

             {/*<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">
                <div className="blog-img-slider">
                  <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    <img src={blogimg1} alt="blog-img" />
                    <img src={blogimg2} alt="blog-img" />
                    <img src={blogimg3} alt="blog-img" />
                  </OwlCarousel>
                </div>
                {/*<div className="single-blog-content">
                  <div className="blog-date">14 nov 2019</div>
                  <h4>
                    <Link to="" className="post-title">
                      contrary to popular belief, lorem ipsum is
                    </Link>
                  </h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered teration some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable.
                  </p>
                  <div className="post-info">
                    <Link to="">by admin</Link>
                    <Link to="">0 comments</Link>
                  </div>
                </div>
              </div>
            </div>*/}

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              {/*<div className="single-blog-wraper mb-30  wow fadeInUp">
                 <div className="blog-video-img text-center position-relative">
                  <img src={blogimg2} alt="blog-img" />
                  <div className="blog-video-icon">
                    <div
                      className="blog-popup-video"
                      onClick={() => setOpen(true)}
                    >
                      <i className="fa fa-play"></i>
                    </div>
                  </div>
                </div>
                <div className="single-blog-content">
                  <div className="blog-date">25 jan 2008</div>
                  <h4>
                    <Link to="" className="post-title">
                      not simply random text. it ha roots in a piece of
                    </Link>
                  </h4>
                  <p>
                    There are many variations of passages of Lorem Ipsum
                    available, but the majority have suffered teration some
                    form, by injected humour, or randomised words which don't
                    look even slightly believable.
                  </p>
                  <div className="post-info">
                    <Link to="">by admin</Link>
                    <Link to="">1 comments</Link>
                  </div>
                </div>
          </div>*/}
                  </div>
            {/*<div className="col-xl-12">
              <div className="merox-pagination mt-20">
                <nav className="pagination">
                  <div className="nav-links">
                    <span aria-current="page" className="page-numbers current">
                      1
                    </span>
                    <Link className="page-numbers" to="">
                      2
                    </Link>
                    <Link className="next page-numbers" to="">
                      <i className="fa fa-long-arrow-right"></i>
                    </Link>
                  </div>
                </nav>
              </div>
        </div>*/}
          </div>
        </div>
      </div>
    </>
  );
};

export default ReinsuranceSolutions;
