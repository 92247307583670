import React from "react";
import OwlCarousel from "react-owl-carousel";
import img1 from "../img/testimonial/client-1.png";
import img2 from "../img/testimonial/client-2.png";
import img3 from "../img/testimonial/client-3.png";
import "owl.carousel/dist/assets/owl.carousel.css";
const Testimonial = () => {
  const options = {
    loop: true,
    autoplay: true,
    smartSpeed: 1000,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      992: {
        items: 2,
      },
      1200: {
        items: 2,
      },
    },
  };
  return (
    <>
      <div
        id="merox-testimonial-area"
        className="merox-testimonial-area mt-100 mb-100 wow fadeInUp"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title">
                <h4>TECHNOLOGIE</h4>
               
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="testimonial-wraper">
                <OwlCarousel className="owl-theme owl-carousel" {...options}>
                  <div className="single-testimonial">
                    <div className="testimonial-img">
                      {/* <img
                        src={img1}
                        className="img-fluid"
                        alt="testimonial-img"
                      />*/}
                    </div>
                    <div className="testimonial-content">
                      <p>
                      La technologie chez X-RE : alimenter les solutions de réassurance de demain
                      Chez X-RE, nous pensons que l’avenir de la réassurance se situe à l’intersection
                      de l’innovation et de la technologie. Notre engagement à repousser les limites de
                      ce qui est possible dans l'industrie est évident à travers notre pile technologique de pointe.
                      Voici comment la technologie détermine la prochaine ère de la réassurance chez X-RE :

                      <p>
                      <b>Hyperion 2.0 : </b>Notre épine dorsale technologique 

                      Hyperion 2.0 est le cœur battant de la prouesse technologique de X-RE.
                      Cette plateforme avancée intègre de manière transparente la puissance des données,
                      de l’analyse et de l’automatisation pour révolutionner la réassurance. Avec Hyperion 2.0,nous pouvons :
                      </p>
                      </p>
                      {/*<div className="title-desig">
                        <h3>daniel wells</h3>
                        <h4>developer</h4>
  </div>*/}
                    </div>
                  </div>

                  <div className="single-testimonial">
                    <div className="testimonial-img">
                      {/*<img
                        src={img2}
                        className="img-fluid"
                        alt="testimonial-img"
/>*/}
                    </div>
                    <div className="testimonial-content">
                      <p>
                      <b>Rationalisez les opérations : </b>Notre plateforme automatise de nombreux processus manuels, réduisant ainsi la paperasse et augmentant l'efficacité. Cela signifie des délais d’exécution plus rapides pour nos clients.
                      <p>
                      <b>Prise de décision basée sur les données : </b>Hyperion 2.0 exploite la puissance du Big Data et de l'analyse pour fournir des informations approfondies. Nous utilisons ces informations pour prendre des décisions éclairées, aidant ainsi nos clients à gérer les risques plus efficacement. 
                      </p>
                   
                      </p>

                      {/*<div className="title-desig">
                        <h3>morgan liu</h3>
                        <h4>expert</h4>
</div>*/}
                    </div>
                  </div>

                  <div className="single-testimonial">
                    <div className="testimonial-img">
                      {/*<img
                        src={img3}
                        className="img-fluid"
                        alt="testimonial-img"
/>*/}
                    </div>
                    <div className="testimonial-content">
                      <p>
                      <b>Personnalisation : </b>Il n'y a pas deux clients identiques, et notre plateforme le reconnaît. Nous proposons des solutions sur mesure qui répondent aux besoins spécifiques de chaque client, grâce à la flexibilité et aux options de personnalisation d'Hyperion 2.0.
                      <p>
                      <b>Transparence : </b>Nous croyons en la transparence, et notre plateforme le reflète. Les clients ont accès en temps réel à leurs données, contrats et statut de réclamation, favorisant ainsi la confiance et la collaboration.
                      </p>
                      </p>
                      {/*<div className="title-desig">
                        <h3>morgan liu</h3>
                        <h4>expert</h4>
</div>*/}
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <div className="testimonial-img">
                      {/*<img
                        src={img3}
                        className="img-fluid"
                        alt="testimonial-img"
/>*/}
                    </div>
                    <div className="testimonial-content">
                      <p>
                      <b>Apprentissage automatique et IA : </b>X-RE exploite le potentiel de l’apprentissage automatique et de l’intelligence artificielle pour prédire les risques avec plus de précision. En analysant les données historiques et en identifiant des modèles, nos algorithmes aident les clients à gérer les risques de manière proactive.
                      <p>
                      <b>Outils actuariels : </b>Notre équipe d'actuaires est équipée d'outils et de logiciels avancés pour modéliser et évaluer les risques avec précision. Cela garantit que nos clients reçoivent des tarifs justes et compétitifs.
                      </p>
                      </p>
                      {/*<div className="title-desig">
                        <h3>morgan liu</h3>
                        <h4>expert</h4>
</div>*/}
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <div className="testimonial-img">
                      {/*<img
                        src={img3}
                        className="img-fluid"
                        alt="testimonial-img"
/>*/}
                    </div>
                    <div className="testimonial-content">
                      <p>
                      <b>Informations statistiques : </b>la technologie de X-RE ne concerne pas seulement l'automatisation ; il s'agit d'idées. Nous fournissons à nos clients des rapports statistiques complets, leur permettant de prendre des décisions fondées sur des données pour leurs besoins de réassurance.
                      <p>
                      <b>Cybersécurité : </b>Avec l'importance croissante de la protection des données, X-RE est à la pointe de la cybersécurité. Nous utilisons des mesures de pointe pour protéger les informations sensibles, garantissant ainsi la confiance et la conformité.
                      </p>
                      <b>Innovation continue : </b>the technological landscape is constantly evolving, and so are we. X-RE is committed to staying at the forefront of technological advances in the insurance and reinsurance sectors.
                      </p>
                      {/*<div className="title-desig">
                        <h3>morgan liu</h3>
                        <h4>expert</h4>
</div>*/}
                    </div>   
                  </div>
                  <div className="single-testimonial">
                    <div className="testimonial-content">
                      <p>
                      <b>Connectivité mondiale : </b>Notre technologie nous permet de nous connecter de manière transparente avec les clients, les partenaires et les marchés du monde entier. Nous comblons les écarts géographiques, rendant la réassurance accessible à l’échelle mondiale.
                      <p>
                      <b>Durabilité : </b>nous ne sommes pas seulement avancés technologiquement ; nous sommes soucieux de l'environnement. X-RE adopte des pratiques durables dans la mesure du possible, contribuant ainsi à un avenir plus vert.
                      </p>
                     
                      </p>
                      {/*<div className="title-desig">
                        <h3>morgan liu</h3>
                        <h4>expert</h4>
</div>*/}
                    </div>
                    </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Testimonial;
