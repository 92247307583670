import React from "react";
import img1 from "../img/team/jkl.jpg";
import img2 from "../img/team/Abbe.jpg";
import img3 from "../img/team/3.jpg";
import img4 from "../img/team/4.jpg";
const Team2 = () => {
  return (
    <>
      <div
        id="merox-team-area"
        className="merox-team-area mt-100 mb-70 wow fadeInUp"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="section-title">
                
              <h4>PRÉSENTATION DE NOTRE ÉQUIPE</h4>
                <p>
                Bienvenue dans le monde dynamique de notre équipe exceptionnelle.
                Forts de notre diversité et de notre expertise collective,
                nous sommes fiers de vous présenter un groupe de professionnels passionnés
                et dédiés à l’excellence et à l’innovation.
                </p>
                <h3>Conseils d'Administration</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">

          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                <div className="team-img">
                   <img src={img1} alt="team" />
                </div>
                <div className="team-content">
                  <h4>Saliou Bakayoko</h4>
                  <span>Président du conseil d’administration</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                <div className="team-img">
                  <img src={img1} alt="team" />
                </div>
               <div className="team-content">
                  <h4>Soumahoro Mori</h4>
                  <span>Administrateur</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                <div className="team-img">
                   <img src={img1} alt="team" />
                </div>
                <div className="team-content">
                  <h4>Alexandre N'djoré</h4>
                  <span>CEO OF DIGITECH AFRICA</span>
                </div>
              </div>
            </div>

            <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="section-title">
                <h4>Qui sommes-nous ?</h4>
                <p>
                Nous sommes une équipe de talents divers, chacun apportant sa propre expérience
                et sa perspective unique. Nos membres, avec leur large éventail de compétences,
                travaillent ensemble pour atteindre des objectifs communs et dépasser les attentes.
                </p>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="section-title">
                  <h3>Membres de l'Equipe</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                <div className="team-img">
                  <img src={img1} alt="team" />
                </div>
               <div className="team-content">
                  <h4>OUIKAHILO BI GORE JEAN-JACQUES</h4>
                  <span>Manager X-RE AFRICA</span>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                <div className="team-img">
                  <img src={img1} alt="team" />
                </div>
               <div className="team-content">
                  <h4>Emmanuel ABBE</h4>
                  <span>Associate Manager  XRE AFRRICA</span>
                </div>
              </div>
            </div>
 
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
              <div className="single-team-wrapper text-center">
                 {/*<div className="team-img">
                  <img src={img4} alt="team" />
                </div>
                <div className="team-content">
                  <h4>shawn philip</h4>
                  <span>designer</span>      
                </div>*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team2;
