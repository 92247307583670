import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import brand from "../img/Logo XRE@2x3.png";

const Header = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);
  // form class add & delete
  const classadd = () => {
    document.querySelector("#search-btn");
    document.querySelector(".search-form-area").classList.add("active");
  };

  const classdelet = () => {
    document.querySelector("#close-btn");
    document.querySelector(".search-form-area").classList.remove("active");
  };

  return (
    <>
      <header>
        <div
          className={
            scroll ? "header-area sticky animated slideInDown" : "header-area"
          }
        >
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="header-top-right float-right">
                  <ul className="header-search">
                    <li>
                      
                    </li>
                  </ul>
                </div>
                <div className="merox-main-menu-area">
                  <nav className="navbar navbar-expand-xl navbar-light main-menu">
                    <Link
                      className="navbar-brand"
                      to={`${process.env.PUBLIC_URL + "/"}`}
                    >
                      <img src={brand} alt="logo" />
                    </Link>
                    <button
                      className="navbar-toggler"
                      type="button"
                      data-toggle="collapse"
                      data-target="#navbarSupportedContent"
                      aria-controls="navbarSupportedContent"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="fa fa-align-left"></i>
                    </button>

                    <div
                      className="collapse navbar-collapse"
                      id="navbarSupportedContent"
                    >
                      <ul className="navbar-nav ml-auto mr-auto">
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`${process.env.PUBLIC_URL + "/"}`}
                          >
                            Accueil
                          </Link>
                        </li>

                            <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                             A Propos de Nous
                          </Link>
                           
                          <div
                            className="dropdown-menu fade-down"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/About"}`}
                            >
                              A Propos de X-RE
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/Guidelines"}`}
                            >
                              Nos Lignes Directrices
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/Values"}`}
                            >
                              Nos Valeurs
                            </Link>
                            <div className="separet-divider"></div>
                          </div>
                        </li>
                        <li className="nav-item dropdown">
                          <Link
                            className="nav-link dropdown-toggle"
                            to="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Nos Solutions
                          </Link>
                          <div
                            className="dropdown-menu fade-down"
                            aria-labelledby="navbarDropdown"
                          >
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/Property"}`}
                            >
                              Réassurance & dommages
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/life-risk"}`}
                            >
                              Réassurance Vie & Santé
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/global-clients"}`}
                            >
                              Clients & Solutions Mondiaux
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/reinsurance-solutions"}`}
                            >
                              Solutions de Réassurance
                            </Link>
                            <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/risk-management"}`}
                            >
                              Gestion des risques 
                            </Link>
                            {/* <div className="separet-divider"></div>
                            <Link
                              className="dropdown-item"
                              to={`${process.env.PUBLIC_URL + "/blog-grid"}`}
                            >
                              Corporate Solutions 
                            </Link> */}
                          </div>
                          
                          
                        </li>

                          <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`${process.env.PUBLIC_URL + "/team"}`}
            
                          >
                             Equipe
                          </Link>
                         
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`${process.env.PUBLIC_URL + "/Testimonial"}`}
                          >
                             Technologie
                          </Link>
                        </li>
                        
                        {/* 
                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`${process.env.PUBLIC_URL + ""}`}
                          >
                            Pays
                          </Link>
                        </li>
                        */}

                        <li className="nav-item">
                          <Link
                            className="nav-link"
                            to={`${process.env.PUBLIC_URL + "/Contact"}`}
            
                          >
                             Contacts
                          </Link>
                         
                        </li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="search-form-area">
          <div className="search-form-centered">
            <div id="search-box">
              <i
                id="close-btn"
                className="fa fa-times fa-2x"
                onClick={classdelet}
              ></i>
              <form className="search-form">
                <input
                  className="form-control"
                  placeholder="Type your text"
                  type="text"
                />
                <button type="submit">
                  <span>Search</span>
                </button>
              </form>
            </div>
        </div>
        </div>*/}
      </header>
    </>
  );
};

export default withRouter(Header);
