import React from "react";
import Breadcrumb1 from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import GlobalClients from "../components/GlobalClients";
import Header from "../components/Header";

const Globalclients = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb1 name="Clients et solutions mondiaux" />
        <GlobalClients />
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default Globalclients;
