import React from "react";
import bg1 from "../img/slider/Img5.jpg"
import bg2 from "../img/slider/Img4.jpg"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
const Mainslider = () => {
  const options = {
    loop: true,
    autoplay: true,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <div id="merox-slider-area" className="merox-slider-area slider-area-bg">
        <div className="merox-main-slider">
          <OwlCarousel className="owl-theme owl-carousel" {...options}>
            <div className="merox-single-slider">
              <div
                className="slid-bg-1"
                style={{ background: `url('${bg1}')` }}
              ></div>
              <div className="merox-single-table">
                <div className="merox-single-tablecell">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8 col-lg-10 col-md-12">
                        <div className="merox-slider-area-content">
                          <h4>X-RE Africa</h4>
                          <h2>
                          Réinventer la réassurance pour un monde connecté <br/> avec <b>X-RE</b> le courtier de réassurance..
                          </h2>
                          <p>
                          X-RE Africa, évalue et propose une bonne couverture de réassurance.
                          </p>
                          <div className="slider-area-btn">
                            {/*<Link to="#" className="btn btn-type-2">
                              get started{" "}
                              <i className="fa fa-long-arrow-right"></i>{" "}
                               </Link>*/}
                            {/*<Link to="#" className="btn btn-type-3">
                              learn more
                              </Link>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="merox-single-slider">
              <div
                className="slid-bg-1"
                style={{ background: `url('${bg2}')` }}
              ></div>
              <div className="merox-single-table">
                <div className="merox-single-tablecell">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-8 col-lg-8 col-md-12">
                        <div className="merox-slider-area-content">
                          <h4>X-RE Africa</h4>
                          <h2>
                          Réinventer la réassurance pour un monde connecté <br/> avec <b>X-RE</b> le courtier de réassurance..
                          </h2>
                          <p>
                          X-RE Africa, évalue et propose une bonne couverture de réassurance.
                          </p>
                          <div className="slider-area-btn">
                            {/*<Link to="" className="btn btn-type-2">
                              get started
                              <i className="fa fa-long-arrow-right"></i>
                            </Link>
                            <Link to="#" className="btn btn-type-3">
                              learn more
                            </Link>*/}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </>
  );
};

export default Mainslider;
