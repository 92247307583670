import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import blogimg1 from "../img/blog/1.jpg";
import blogimg2 from "../img/blog/2.jpg";
import blogimg3 from "../img/blog/3.jpg";
import { Link } from "react-router-dom";
const LifeRisk = () => {
  const [isOpen, setOpen] = useState(false);

  const options = {
    loop: true,
    autoplay: true,
    animateIn: "zoomInDown",
    smartSpeed: 5000,
    dots: true,
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="snvzakfcTmY"
        onClose={() => setOpen(false)}

      />
      <div className="single-blog-content" style={{ textAlign: 'center', color: 'red', fontSize: '24px' }}>
  <h4>
    <Link to="#" className="post-title">
      Le Risque de Vie
    </Link>
  </h4>
</div>


      <div id="merox-blog-area" className="merox-blog-area mt-100 mb-100">
     

        <div className="container">
          <div className="row">
            
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">
                <div className="blog-img-slider">
                  <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    <img src={blogimg1} alt="blog-img" />
                    <img src={blogimg2} alt="blog-img" />
                    <img src={blogimg3} alt="blog-img" />
                  </OwlCarousel>
                </div>
                <div className="single-blog-content">
                 
                  
                  <p>
                  <p>
                  Chez X-RE, nous comprenons l'impact profond que les risques de la vie peuvent avoir sur les individus et les familles.
                  </p>

                  <p><b>Couverture complète : </b>: Nous proposons une large gamme de solutions d’assurance vie et de réassurance.</p>
                  
                  <p><b>Évaluation des risques : </b>nos experts sont qualifiés pour évaluer et gérer les risques de la vie. Nous exploitons les données, les analyses et agissons.</p>
                 
                  <p><b>Innovation : </b>X-RE s'engage à rester à l'avant-garde.</p>

                  <p><b>Services de soutien : </b>Au-delà des polices d'assurance, nous offrons des services de soutien pour aider les clients à planifier leur stratégies.</p>

                  <p> <b>Centré sur le client : </b>Nous nous concentrons toujours sur le client. Nous croyons en une communication transparente, un service exceptionnel et l'établissement de relations à long terme basées sur la confiance et la fiabilité.</p>

                  </p>

                </div>
              </div>
            </div>

            

             <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="single-blog-wraper mb-30  wow fadeInUp">
                <div className="blog-img-slider">
                  <OwlCarousel className="owl-carousel owl-theme" {...options}>
                    <img src={blogimg1} alt="blog-img" />
                    <img src={blogimg2} alt="blog-img" />
                    <img src={blogimg3} alt="blog-img" />
                  </OwlCarousel>
                </div>
                <div className="single-blog-content">
                  
                 
                  <p>
                    
                  <p><b>Expertise mondiale : </b>Grâce à une présence mondiale, nous pouvons répondre aux risques liés à la vie dans diverses régions et marchés. Que vous ayez besoin d'une couverture pour vous-même, votre famille ou vos employés, nous avons ce qu'il vous faut.</p>
                  
                  <p><b>Durabilité : </b>Nous comprenons l’importance des pratiques d’assurance-vie durables. Nos solutions tiennent compte du bien-être à long terme de nos clients et des communautés que nous servons.</p>

                  <p><b>Tranquillité d'esprit : </b>: La vie est imprévisible, mais avec les solutions de risques pour la vie de X-RE, vous pouvez avoir l'esprit tranquille en sachant que vous et découvrez comment les solutions risques vie de X-RE peuvent protéger votre avenir. </p>

                  <p>Contactez-nous dès aujourd’hui pour explorer nos options d’assurance vie et de réassurance personnalisées adaptées à vos besoins et à votre situation unique. Votre protection et votre tranquillité d'esprit sont nos priorités.</p>
                  </p>
                 
                </div>
              </div>
            </div>

            
                  
            <div className="col-xl-12">
              <div className="merox-pagination mt-20">
                <nav className="pagination">
                  <div className="nav-links">
                    {/*<span aria-current="page" className="page-numbers current">
                      
  </span>*/}
                   {/* <Link className="page-numbers" to="">
                      2
                    </Link>
                    <Link className="next page-numbers" to="">
                      <i className="fa fa-long-arrow-right"></i>
  </Link>*/}
                  </div>
                </nav>
              </div>
        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LifeRisk;
