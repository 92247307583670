import React from "react";
import brand from "../img/Logo XRE@2x.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <footer>
        <div id="merox-footer-area" className="merox-footer-area pt-100 pb-70">
          <div className="container">
            <div className="row">
              <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12">
                <div className="footer-single-wedget">
                  <div className="custom-html-widget">
                    <div className="footer-logo">
                      <Link to="#">
                        <img
                          src={brand}
                          alt="footer-logo"
                          className="img-fluid"
                        />
                      </Link>
                    </div>

                    
                  </div>
                </div>
              </div>

              {/*<div className="col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="footer-single-wedget">
                  <div className="widget_nav_menu">
                    <h3>quick link</h3>
                    <ul className="menu">
                      <li>
                        <Link to="">help center </Link>
                      </li>
                      <li>
                        <Link to="">privicey police</Link>
                      </li>
                      <li>
                        <Link to="">support policy</Link>
                      </li>
                      <li>
                        <Link to="">terms & max</Link>
                      </li>
                      <li>
                        <Link to="">about us</Link>
                      </li>
                      <li>
                        <Link to="">contact</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

  */}
              <div className="col-xl-6 col-lg-8 col-md-6 col-sm-12">
                <div className="footer-single-wedget">
                  <div className="custom-html-widget">
                    <h3>Contactez-nous</h3>
                    <ul className="contact-details">
                      <li>
                        <i className="fa fa-map-marker"></i> Adresse : Immeuble Cube 2e étage, Angré 9è Tranche, Abidjan
                        
                      </li>
                      <li>
                        <i className="fa fa-headphones"></i> Support d'Assistance
                      </li>
                      <li>
                        <i className="fa fa-clock-o"></i> Horaires de travail : Lundi-Vendredi 9h-17h
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="merox-copyright-area pt-30 pb-30">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12">
                <div className="footer-copyright text-center">
                  <p>
                    © 2023 X-RE | Tout Droits Réservés
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
