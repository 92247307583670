import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Team from "./pages/Team";
import Values from "./pages/Values";
import Testimonial from "./pages/Testimonial";
import Guidelines from "./pages/Guidelines";
import Property from "./pages/Property";
import Error from "./pages/Error";
import Liferisk from "./pages/LifeRisk";
import GlobalClients from "./pages/GlobalClients";
import ReinsuranceSolutions from "./pages/ReinsuranceSolutions";
import Riskmanagement from "./pages/Riskmanagement";
import Blogdetails from "./pages/Blogdetails";
import Contact from "./pages/Contact";
function App() {
  return (
    <Router>
      <Switch>
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/about"}`}
          component={About}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/team"}`}
          component={Team}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/testimonial"}`}
          component={Testimonial}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/error"}`}
          component={Error}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/property"}`}
          component={Property}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/life-risk"}`}
          component={Liferisk}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/global-clients"}`}
          component={GlobalClients}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/reinsurance-solutions"}`}
          component={ReinsuranceSolutions}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/risk-management"}`}
          component={Riskmanagement}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/blog-details"}`}
          component={Blogdetails}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/contact"}`}
          component={Contact}
        />
        <Route
          exact
          //path={`${process.env.PUBLIC_URL + "/"}`}
          path= "/"
          component={Home}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/Values"}`}
          component={Values}
        />
        <Route
          exact
          path={`${process.env.PUBLIC_URL + "/Guidelines"}`}
          component={Guidelines}
        />
      </Switch>
    </Router>
  );
}

export default App;
