import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import ReinsuranceSolutions from "../components/ReinsuranceSolutions";
import Header from "../components/Header";

const LIFERISK = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb name="Solutions de réassurance" />
        <ReinsuranceSolutions />
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default LIFERISK;
