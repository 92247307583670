import React from "react";
import Breadcrumb from "../components/Breadcrumb";
import Footer from "../components/Footer";
import Scrolltop from "../components/Scrolltop";
import LifeRisk from "../components/LifeRisk";
import Header from "../components/Header";

const Liferisk = () => {
  return (
    <>
      <Header />
      <main>
        <Breadcrumb name="Life & Health Reinsurance" />
        <LifeRisk />
        <Footer />
      </main>
      <Scrolltop />
    </>
  );
};

export default Liferisk;
